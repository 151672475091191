import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostLink from "../components/PostLink";

const BlogListPage: React.FC = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const Posts = edges
        .filter((edge) => !!edge.node.frontmatter.created_at)
        .map((edge) => {
            console.log("HERE", edge.node.id);
            return <PostLink key={edge.node.id} post={edge.node} />;
        });

    return (
        <Layout>
            <SEO title="Blog" />

            <section className="section">
                <div className="container content">
                    <h1 className="title is-1">Blog</h1>

                    <div>{Posts}</div>
                </div>
            </section>
        </Layout>
    );
};

export default BlogListPage;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___created_at] }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        created_at
                        path
                        title
                        author
                    }
                }
            }
        }
    }
`;
