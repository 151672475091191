import React from "react";
import { Link } from "gatsby";
// import { formatDate } from "../helpers/formatters";
// import styled from "styled-components";

const PostLink = ({ key, post }) => {
    return (
        <div className="post-link-component box" key={key}>
            <h2 className="title is-2">
                <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
            </h2>
            {/*
            <div className="metadata">
                Posted by {post.frontmatter.author} on{" "}
                {formatDate(post.frontmatter.created_at)}
            </div>
            */}
            <div className="excerpt">{post.excerpt}</div>
            {/*
                <Link
                to={post.frontmatter.path}
                className="button is-link is-pulled-right"
            >
                Read more
            </Link>
            */}
        </div>
    );
};
export default PostLink;
